@font-face {
ascent-override: 90%;
font-family: '__arminFont_46c2ec';
src: url(https://static.uat.carma.com.au/_next/static/media/f8c767ee538731e3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
ascent-override: 90%;
font-family: '__arminFont_46c2ec';
src: url(https://static.uat.carma.com.au/_next/static/media/058150047b5a6f4e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
ascent-override: 90%;
font-family: '__arminFont_46c2ec';
src: url(https://static.uat.carma.com.au/_next/static/media/f48748d064c1146e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__arminFont_Fallback_46c2ec';src: local("Arial");ascent-override: 76.58%;descent-override: 21.47%;line-gap-override: 39.02%;size-adjust: 101.99%
}.__className_46c2ec {font-family: '__arminFont_46c2ec', '__arminFont_Fallback_46c2ec'
}.__variable_46c2ec {--font-armin: '__arminFont_46c2ec', '__arminFont_Fallback_46c2ec'
}

@font-face {
font-family: '__carmaTitleFont_0e4fdc';
src: url(https://static.uat.carma.com.au/_next/static/media/52fc5978e7ce83ce-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__carmaTitleFont_Fallback_0e4fdc';src: local("Arial");ascent-override: 101.83%;descent-override: 32.39%;line-gap-override: 0.00%;size-adjust: 96.48%
}.__className_0e4fdc {font-family: '__carmaTitleFont_0e4fdc', '__carmaTitleFont_Fallback_0e4fdc';font-weight: 800;font-style: normal
}.__variable_0e4fdc {--font-carmatitle: '__carmaTitleFont_0e4fdc', '__carmaTitleFont_Fallback_0e4fdc'
}

